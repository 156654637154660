.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.multiline-cell {
  height: auto !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
}

.badge {
  background-color: #61dafb;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline {
  outline: none !important;
  box-shadow: none !important;
}

.fw-700 {
  font-weight: 700;
}

.mh-59-vh {
  max-height: 59vh;
}

.mh-50-vh {
  max-height: 42vh;
}

.react-colorful {
  margin: -5px 0px 0px 35px;
}

.react-colorful__pointer {
  width: 20px !important;
  height: 20px !important;
}

a {
  cursor: pointer;
}

.invalid::placeholder {
  color: red;
}

.MuiPagination-ul li {
  /* box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px; */
  min-height: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px 2px 0px 2px;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: rgba(255, 183, 0, 1) !important;
  /* background-color: #007bff !important; */
}

.MuiPaginationItem-root {
  margin: 0 !important;
  min-height: 20px;
  height: 20px !important;
  min-width: 20px !important;
  width: 20px !important;
}

.MuiPaginationItem-rounded {
  border-radius: 0 !important;
}

.MuiPaginationItem-icon {
  /* color: #F26324; */
  color: #000;
}

.MuiPagination-ul li:nth-child(-n + 2) {
  /* background-color: #FCE0D3; */
  background-color: #fff;
}

.MuiPagination-ul li:nth-last-child(-n + 2) {
  /* background-color: #FCE0D3; */
  background-color: #fff;
}

.page_link.active .icons {
  text-decoration: none;
  color: rgba(255, 183, 0, 1);
}

.PhoneInputInput {
  border: none !important;
}

.bg_wait {
  /* background: #99ddf9!important; */
  background: #095998 !important;
  color: #fff !important;
}

.bg_wait_1m {
  /* background: #0387bd!important; */
  background: #095998 !important;
  color: #fff !important;
}

.bg_wait_5m {
  background: #095998 !important;
  color: #fff !important;
}

.bg_oncall {
  /* background: #94f494!important; */
  background: #6c6 !important;
  color: #fff !important;
}

.bg_oncall_1m {
  background: #6c6 !important;
  /* background: #079e06!important; */
  color: #fff !important;
}

.bg_oncall_5m {
  background: #079e06 !important;
  color: #fff !important;
}

.bg_pause {
  /* background: #fbc199!important; */
  background: #f3a34f !important;
  color: #fff !important;
}

.bg_pause_1m {
  background: #f3a34f !important;
  color: #fff !important;
}

.bg_pause_5m {
  background: #f76e00 !important;
  color: #fff !important;
}

.bg_dispo {
  /* background: #ff2424!important; */
  background: #ce2424 !important;
  color: #fff !important;
}

.bg_dispo_1m {
  background: #ce2424 !important;
  color: #fff !important;
}

.bg_dispo_5m {
  background: #900 !important;
  color: #fff !important;
}

.bg_queuee {
  background: #fc0 !important;
  color: #fff !important;
}

.bg_way3 {
  background: #7d047d !important;
  color: #fff !important;
}

.bg_dead {
  background: #bdbcbc !important;
  color: #fff !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #f76e00;
}

.carousel-item {
  cursor: pointer;
}

/*  */

.menu {
  font-size: 14px;
  background-color:
    /* rgba(244, 100, 36,0.6); */
    rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  padding: 5px 0 5px 0;
  /* width: 150px; */
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.col_1_5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13%;
  flex: 0 0 12%;
  max-width: 13%;
}

.col_1_5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2px;
  padding-left: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}

.map-filter-active {
  background-color: #dddddd !important;
}

.wms-hysearch-result {
  font-size: 12px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  height: 62px;
  box-sizing: border-box;
}

.wms-imgcollage {
  position: relative;
  float: left;
  margin-right: 10px;
}

.wms-imgcollage,
.wms-imgcoll1:only-child,
.wms-imgcoll1:hover,
.wms-imgcoll2:hover,
.wms-imgcoll3:hover,
.wms-imgcoll4:hover {
  height: 42px;
  width: 42px;
  line-height: 42px;
  min-width: 42px;
  min-height: 42px;

}

.wms-hysearch * {
  box-sizing: content-box;
}

.wms-lettercoll,
.wms_badge_letter,
.wms_hysearch_addicon {
  /* background: linear-gradient(to bottom,#f19b55,#f76e00); */
  /* background: linear-gradient(to bottom,#56ccf2,#208aed); */
  background-color: #eeecec;
  color: #646464;
}

.wms-lettercoll,
.wms_badge_letter {
  border-radius: 50%;
  /* color: #fff; */
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.wms-lettercoll,
.wms_badge_letter {
  border-radius: 50%;
  /* color: #fff; */
  text-align: center;
}

.wms-hysearch-result-name {
  color: #141414;
  font-size: 15px;
  line-height: initial;
  width: 80%;
  font-weight: 600;
}

.wms-truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wms-hysearch-result-desc {
  color: #888;
  height: 22px;
  line-height: 2;
  width: 70%;
  display: inline-block;
}

.wms-hysearch-closeicon {
  height: 34px;
  width: 34px;
  font-size: 17px;
  background: #fff;
  position: fixed;
  right: 200px;
  border-radius: 50%;
  top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 8px) scale(1) !important;
}

.MuiFormLabel-root {
  transform: translate(14px, 8px) scale(1) !important;
}

.MuiInputLabel-root.Mui-focused {
  transform: translate(14px, -9px) scale(0.75) !important;
}

.MuiInputLabel-shrink {
  transform: translate(14px, -9px) scale(0.75) !important;
}

.css-1ro5ixx-MuiFormControl-root {
  background-color: #fff;
}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  background-color: #fff;
} */
.div {
  padding: 30px;
  background: url(https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSkAuRoZnkLBZlEYtgL5GJ5w_2Tufpxh5PqkOf-Negy7eL-JGC5Mk-DW-I) no-repeat center center;
  background-size: cover;
}

.fieldset {
  border: 2px solid black;
  padding: 10px;
}


.tab-li a {
  color: #6C6F78;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 15px 25px;
  width: 40px;
  margin-right: 10px;
  font-weight: 600;
}

.tab-li a:hover {
  text-decoration: none;
}

.tab-li a.active {
  background: rgba(255, 183, 0, 1);
  color: #fff;
  border: 1px solid rgba(255, 183, 0, 1);
  padding: 15px 25px;
  margin-right: 10px;
  font-weight: 600;
}

.audio-greets-tabs .nav.nav-tabs {
  border-bottom: 0;
}

.audio-greets-tabs .nav.nav-tabs .tab-li a {
  /* border-bottom: 0; */
  text-decoration: none;
  border-color: #dee2e6;
}

a {
  text-decoration: none;
}

.message-cell-content {
  max-height: 100px;
  /* Set maximum height */
  overflow-y: auto;
  /* Enable vertical scrolling */
}