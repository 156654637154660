.avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #F26325;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: solid 2px #F26325;
    position: relative;
}

.greenDot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #0f0;
    border: solid 1px #0b0;
    border-radius: 4px;
    right: 0;
    bottom: 0;
}

.count {
    position: absolute;
    height: 14px;
    min-width: 14px;
    background-color: #0b0;
    border: solid 1px rgb(8, 81, 122);
    font-size: 8px;
    font-weight: 500;
    color: #fff;
    border-radius: 7px 7px 7px 7px;
    right: -5px;
    top: -5px;
    text-align: center;
}