.headings {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  padding-bottom: 40px;
  margin-left: 10px;
  height: 20px;
}
.headings::after {
  border: solid #f76e00 1px;
  content: " ";
  width: 30px;
  position: absolute;
  top: 25px;
  left: 0;
}
.col_1 {
  height: 500px;
  overflow-y: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 99;
}
.header {
  height: 60px;
  display: inline-block;
  background: #f8f8f8;
  border-radius: 10px 10px 0 0;
  width: 100%;
  box-sizing: border-box;
}

.my_modal {
  /* position: fixed; */
  z-index: 1000;
  width: auto;
  max-width: 1024px;
  min-width: 730px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  top: 32px;
  left: 50%;
  background: white;
  border-radius: 5px;
  z-index: 200;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  /* transform: translateX(-50%); */
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 15px;
}
.modal_wrapper {
  background: linear-gradient(
    180deg,
    #fafafa 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  padding-top: 40px;
  border-radius: 15px;
  max-width: 994px;
  height: 100%;
}
.my_modal__header {
  background-color: #f3804e;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin-top: 40px; */
  border-radius: 200px;
  box-shadow: 0px 4px 31px 0px #00000040;

  /* border-bottom: 2px solid #3b0062; */
}

.my_modal__header h1 {
  font-size: 1.5rem;
  /* color: #3b0062; */
  color: white;

  margin: 1rem;
}

.my_modal__content {
  padding: 1rem;
}

.my_modal__actions {
  padding: 1rem;
  text-align: right;
}

.my_modal__actions button {
  margin: 0 0.5rem;
}
.bg_submit {
  background-color: rgba(255, 183, 0, 1);
  color: white;
}
.bg_cancel {
  background-color: #042b42;
  color: white;
}
.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_input {
  outline: 0;
  border: 0;
  font-size: 17px;
  height: 56px;
  width: 50%;
  box-shadow: none;
  display: inline-block;
  font-family: inherit;
  margin-left: 20px;
  box-shadow: none !important;
  border: 0 !important;
  background: #f8f8f8;
}
.msg_input {
  outline: 0;
  border: 0;
  font-size: 17px;
  height: 56px;
  /* width: 50%; */
  box-shadow: none;
  display: inline-block;
  font-family: inherit;
  /* margin-left: 20px; */
  box-shadow: none !important;
  border: 0 !important;
  background: #f8f8f8;
}
.send_button {
  outline: 0;
  border: 0;
  font-size: 17px;
  height: 56px;
  /* width: 50%; */
  box-shadow: none;
  display: flex;
  font-family: inherit;
  /* margin-left: 20px; */
  box-shadow: none !important;
  border: 0 !important;
  background: #042b42;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Chats */
.msg_content {
  font-size: 14px;
  color: #667781;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-family: "SFProTextMedium";
}
.card_title {
  font-size: 16px;
  color: #111b21;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-family: "SFProTextMedium";
}

.msg_time {
  font-size: 12px;
  color: #667781;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-family: "SFProTextMedium";
  text-align: end;
}
.card {
  cursor: pointer;
}
.card:hover {
  background-color: #f5f6f6;
}
.unread_indicator {
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
  background-color: #25d366;
  overflow: hidden;
  height: 20px;
  width: auto;
  min-width: 20px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 15px;
  text-align: center;

  /* white-space: nowrap; /* Don't forget this one 
      text-overflow: ellipsis;
    font-family: "SFProTextMedium"; */
}
