.card {
  cursor: pointer;
  list-style: none;
  background: #f5f5f5;
  padding: 10px 15px;
  border-bottom: 1px solid #cccccc;
  font-size: 12px;
  transition: all 0.3s;
  position: relative;
}
.card h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 0 10px;
}
.card p {
  orphans: 3;
  widows: 3;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
.wrapper {
  overflow-y: auto;
  height: 76vh;
}
.info_window {
  min-width: 350px;
}
.info_window ul {
  margin-left: 0;
}
.info_window ul li {
  list-style: none;
  margin-bottom: 5px;
  padding: 5px 0px 10px 0px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}
.header {
  background: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #d6dadc;
}
.button_group {
  display: inline-block;
  zoom: 1;
  font-size: 13px;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
  padding-left: 1px;
}
.button {
  /* border-bottom-left-radius: 2px; */
  border-left-width: 1px;
  /* border-top-left-radius: 2px; */
  background: #ececec;
  border: 0;
  /* border-radius: 2px; */
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 10px 15px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  font-weight: 500;
  text-decoration: none;
  /* color: #fff; */
  text-align: center;
  letter-spacing: 0.5px;
  transition: 0.2s ease-out;
  cursor: pointer;
  font-family: "Poppins";
  color: #404040 !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  line-height: 2.6em !important;
}
.search_icon {
  position: absolute;
  top: 3px;
  left: 10px;
  border-right: 1px #dee2e6 solid;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}

.wrapper h3 {
  margin-bottom: 0px;
  font-size: 12px;
  padding: 10px 10px;
  font-weight: 500;
}
