/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
/* #fff */
.dot_pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  /* margin-top: 15px; */
  margin-left: 20px;
  display: flex;
  align-self: center;
}

.dot_pulse::before,
.dot_pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}

.dot_pulse::before {
  box-shadow: 9984px 0 0 -5px #fff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot_pulse::after {
  box-shadow: 10014px 0 0 -5px #fff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}
.loader_bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11111;
}
.loading_wrapper {
  display: flex;
  flex-direction: row;
}
.loading_text {
  color: #fff;
}
@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
}
