@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  svg {
    display: initial;
    vertical-align: text-top;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  h1 {
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: top;
    color: #767676;
    /* font-family: Poppins, sans-serif; */
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: fit-content;
    overflow: hidden;
  }
  thead {
    background-color: rgba(0, 0, 0, 0.07);
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  th {
    border-bottom: 2px solid #dee2e6;
  }
  label {
    margin-bottom: 0;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f5f5f5;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 100vh;
    /* border: 3px solid #f6f7ed; */
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    width: 10px;
  }
}

@layer components {
  /* Buttons */
  .btnPrimary {
    @apply px-4 py-2 font-medium flex items-center justify-center gap-2 bg-primary-100 whitespace-nowrap text-center capitalize text-white rounded-md cursor-pointer transition-all;
  }
  .btnPrimary:hover {
    @apply opacity-90;
  }
  .btnPrimary:disabled {
    @apply opacity-50;
  }

  .btnSecondary {
    @apply px-4 py-2 whitespace-nowrap font-medium text-center text-white capitalize shadow-md dark:shadow-white/5 shadow-gray-300 rounded cursor-pointer;
  }
  .btnSecondary:active:not(:disabled) {
    @apply scale-105 transition-all;
  }
  .btnSecondary:hover:not(:disabled) {
    @apply opacity-90;
  }
  .btnSecondary:disabled {
    @apply bg-[#aaaaaa];
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invalid {
  border-color: #f00 !important;
  color: #f00 !important;
  background-color: #ff000005 !important;
}

.invalid * {
  color: #f00 !important;
  background-color: #ff000005 !important;
}

* {
  outline: none !important;
  box-sizing: border-box;
}
.my_navIcon {
  color: #343434;
  cursor: pointer;
  width: 24px;
  padding-left: 2px;
  padding-right: 2px;
  align-self: stretch;
  transition: 0.2s;
  display: initial;
}

.my_navIcon:hover {
  color: #34343460;
}
.select_transform {
  transform: translate(14px, 8px) scale(1) !important;
}
.p-multiselect .p-multiselect-label {
  padding: 6px 8px 6px 8px !important;
}
.p-multiselect-filter-container .p-inputtext {
  height: 38px;
}
.p-multiselect-filter-container .p-inputtext:focus {
  box-shadow: none !important;
}
.p-multiselect .p-focus {
  box-shadow: none !important;
  border-color: #ced4da;
}
.p-dropdown .p-dropdown-label {
  padding: 6px 8px 6px 8px !important;
}
.p-dropdown-filter-container .p-inputtext {
  height: 38px;
}
.p-dropdown-filter-container .p-inputtext:focus {
  box-shadow: none !important;
}
.p-dropdown .p-focus {
  box-shadow: none !important;
  border-color: #ced4da;
}

.table .custom-height {
  padding: 5px;
}
.table .custom-header-height {
  padding: 5px;
}
p {
  margin-bottom: 0 !important;
}
/* div[role="menubar"].gmnoprint {
  display: none;
} */
/* .css-6hp17o-MuiList-root-MuiMenu-list {
  display:flex;
  flex-direction:column;
  } */
.background-gradient {
  content: "";
  /* position: absolute; */
  left: 0;
  top: 0;
  /* width: 100%;
  height: 100%; */
  background: linear-gradient(transparent, white);
  z-index: 1;
  pointer-events: none;
}
/* styles.css */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-blink {
  animation: blink 2s infinite;
}
.p-button {
  border-width: 0 !important;
  padding: 6px 12px !important;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
}
.p-button span {
  font-weight: 700;
  font-size: 14px !important;
}
.p-button:focus {
  box-shadow: none !important;
  background: rgba(255, 183, 0, 1) !important;
  /* border-radius: 0!important; */
}
.p-selectbutton .p-highlight {
  background: rgba(255, 183, 0, 1) !important;
  /* border-radius: 0!important; */
}
.testVertical {
  writing-mode: vertical-rl;
  /* height:60px; */
  /* rotate:180deg; */
  font-weight: 500;
  /* width:85px; */
  width: 103px;
  text-orientation: mixed;
}

.inputField {
  border-radius: .25rem;
  border-width: 1px;
  outline: 2px solid #0000;
  outline-offset: 2px;
  padding: .5rem 1rem;
  width: 100%;
}

/* Extend Tailwind CSS with custom scrollbar styles */
@layer components {
  .scrollbar-custom {
    scrollbar-width: thin;
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(128, 128, 128, 0.125);
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    border-radius: 100px;
  }
}
.radar-autocomplete-results-list {
  min-width: 500px;
  z-index: 9999 !important;
  background-color: white
}

/* Radar Autocomplete Dropdown Styles */
.radar-ui-autocomplete {
  z-index: 9999 !important;
}

.radar-ui-autocomplete__suggestions-container {
  z-index: 9999 !important;
}